<template>
    <div class="panel">
        <div class="panel-heading flex items-center gap-2 cursor-pointer" @click="showPanel = !showPanel">
            <i :class="panelIconClass" />
            <h4 class="font-bold">{{ title }}</h4>
            <a :href="popupHelpLink" target="_blank">
                <i class="fas fa-circle-question" />
            </a>
            <span class="grow"></span>
            <Button variant="primary" fill="solid" @click.stop="addAlert()">
                <i class="fas fa-plus text-xs" />
                <span>{{ $t("Global.Add") }}</span>
            </Button>
        </div>
        <div v-if="showPanel" class="panel-body">
            <table v-if="hasAlerts" class="table table-fixed">
                <thead>
                    <tr class="text-left">
                        <th class="w-64">{{ $t("Global.Quantity") }}</th>
                        <th class="w-48">{{ $t("Notifications.IncreaseOrDecrease") }}</th>
                        <th>{{ $t("Notifications.Amount") }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <NotificationPriceAlert
                        v-if="newAlert"
                        :alert="newAlert"
                        class="border-t border-gray-100"
                        @save="onNewSave"
                        @cancel="newAlert = null" />
                    <NotificationPriceAlert
                        v-for="alert in props.alerts"
                        :key="alert.Id"
                        :alert
                        class="border-t border-gray-100"
                        @save="emits('update', $event)"
                        @delete="emits('delete', $event)" />
                </tbody>
            </table>
            <div v-else class="p-3 italic">
                {{ $t("Notifications.AddAlertMessage") }}
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
const { t } = useI18n();
const props = defineProps<{ alerts: PriceAlert[] }>();
const emits = defineEmits<{
    add: [alert: PriceAlert];
    update: [alert: PriceAlert];
    delete: [id: number];
}>();

const newAlert = ref<PriceAlert | null>(null);
const showPanel = ref(true);

const popupHelpLink = useDocsUrl("/stock-and-price-alerts.html");

const title = computed(() => t("Notifications.PriceAlertsCount", [props.alerts.length]));
const panelIconClass = computed(() => ["fass", showPanel.value ? "fa-chevron-down" : "fa-chevron-up"]);
const hasAlerts = computed(() => props.alerts.length > 0 || newAlert.value != null);

function addAlert() {
    newAlert.value = {
        Id: 0,
        Quantity: 0,
        NotificationId: 0,
        Amount: 0,
        Modifier: MODIFIER_TYPE.GREATER_THAN,
        Status: AlertStatus.Enabled,
    };
}

function onNewSave(alert: PriceAlert) {
    emits("add", alert);
    newAlert.value = null;
}
</script>

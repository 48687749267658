<template>
    <tr v-if="isEditing && tempAlert">
        <td>
            <input v-model.number="tempAlert.Quantity" type="number" step="10" min="1" />
            <div class="text-danger">{{ errorQuantity }}</div>
        </td>
        <td class="mt-2">
            <NotificationModifier v-model="tempAlert.Modifier" />
        </td>
        <td>
            <div class="input-group input-group-with-button">
                <input v-model="tempAlert.Amount" type="number" min="1" max="100" />
                <span class="input-group-after btn hover:bg-white">%</span>
            </div>
            <div class="text-danger">{{ errorAmount }}</div>
        </td>
        <td class="text-right">
            <Button class="rounded-full" variant="primary" @click="saveAlert()">
                <span>{{ $t(tempAlert.Id > 0 ? "Global.Confirm" : "Notifications.Save") }}</span>
            </Button>
            <Button class="rounded-full ml-2" variant="danger" @click="cancelEdit()">
                {{ $t("Global.Cancel") }}
            </Button>
        </td>
    </tr>
    <tr v-else>
        <td>{{ quantityFormatted }}</td>
        <td><ModifierBadge :modifier="props.alert.Modifier" class="py-1 px-2" /></td>
        <td>{{ props.alert.Amount }}%</td>
        <td class="text-right">
            <Button
                class="rounded-full"
                variant="primary"
                :title="$t('Global.Edit')"
                :disabled="isDeleted"
                @click="editAlert()">
                {{ $t("Global.Edit") }}
            </Button>
            <Button
                class="rounded-full ml-2"
                variant="danger"
                :title="$t('Global.Delete')"
                :disabled="isDeleted"
                @click="emits('delete', props.alert.Id)">
                {{ $t("Global.Delete") }}
            </Button>
        </td>
    </tr>
</template>
<script setup lang="ts">
const { t } = useI18n();
const props = defineProps<{ alert: PriceAlert }>();
const emits = defineEmits<{ save: [value: PriceAlert]; delete: [id: number]; cancel: [] }>();

const isEditing = ref(props.alert.Id < 1);
const tempAlert = ref<PriceAlert>({ ...props.alert });
const errorQuantity = ref("");
const errorAmount = ref("");

const isDeleted = computed(() => props.alert.Status == AlertStatus.Deleted);
const quantityFormatted = computed(() => props.alert.Quantity.toLocaleString());

function validate(): boolean {
    errorQuantity.value = "";
    errorAmount.value = "";
    if (!tempAlert.value) return false;

    if (tempAlert.value.Amount < 1 || tempAlert.value.Amount > 100) {
        errorAmount.value = t("Notifications.AlertInvalidAmount");
        return false;
    }

    if (tempAlert.value.Quantity < 1) {
        errorQuantity.value = t("Notifications.AlertInvalidQuantity");
        return false;
    }

    return true;
}

function editAlert() {
    tempAlert.value = { ...props.alert };
    isEditing.value = true;
}

function saveAlert() {
    // final validate
    if (!validate()) return;

    emits("save", { ...tempAlert.value });
    isEditing.value = false;
}

function cancelEdit() {
    emits("cancel");
    isEditing.value = false;
}
</script>

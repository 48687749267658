<template>
    <Badge variant="primary" :class="props.class">
        <i :class="iconClasses" />
    </Badge>
</template>

<script setup lang="ts">
import type { HTMLAttributes } from "vue";

const props = defineProps<{
    class?: HTMLAttributes["class"];
    modifier: Modifier;
}>();

const iconClasses = `text-white text-xs fas fa-caret-${props.modifier === Modifier.GreaterThan ? "up" : "down"}`;
</script>

<template>
    <DialogContent id="notification-preview" class="md:max-w-4xl">
        <DialogTitle class="text-3xl font-medium">
            {{ $t("Notifications.NotificationPreviewHeader") }}
        </DialogTitle>
        <div class="text-xl font-extralight">
            {{ $t("Notifications.NotificationPreviewDesc") }}
            <a :href="sampleEmailUrl" rel="noopener" target="_blank">
                {{ $t("Notifications.SeeASample") }}
            </a>
        </div>
        <div class="text-xl font-extralight" v-html="signinText" />
        <div>
            <DialogClose as-child>
                <button type="button" class="btn">
                    {{ $t("Global.Close") }}
                </button>
            </DialogClose>
        </div>
    </DialogContent>
</template>

<script setup lang="ts">
const { t } = useI18n();
const config = useRuntimeConfig();
const localePath = useLangPath();
const loginUrl = localePath("/sign-in");
const registerUrl = localePath("/register");

const sampleEmailUrl = config.public.cdnUrl + "/assets/images/sample-alert-email.pdf";
const signinText = computed(() => t("Notifications.NotificationPreviewSignIn", [loginUrl, registerUrl]));
</script>

<template>
    <ButtonGroup>
        <Button :variant="greaterVariant" size="xs" fill="solid" @click="model = MODIFIER_TYPE.GREATER_THAN">
            <i class="fas fa-caret-up" />
        </Button>
        <Button :variant="lesserVariant" size="xs" fill="solid" @click="model = MODIFIER_TYPE.LESS_THAN">
            <i class="fas fa-caret-down" />
        </Button>
    </ButtonGroup>
</template>
<script setup lang="ts">
const model = defineModel<number>();
const greaterVariant = computed(() => (model.value == MODIFIER_TYPE.GREATER_THAN ? "primary" : "default"));
const lesserVariant = computed(() => (model.value == MODIFIER_TYPE.LESS_THAN ? "primary" : "default"));
</script>

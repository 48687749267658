<template>
    <Dialog v-model:open="isOpen">
        <DialogContent v-if="isAuthenticated" id="notification" class="md:max-w-4xl">
            <DialogTitle class="text-xl">
                {{ $t("Notifications.ProductNotification") }}
            </DialogTitle>
            <div v-if="notification && notification.Part" class="flex flex-col md:flex-row gap-2">
                <PartImage size="md" :image-url="notification.Part.ImageUrl" />
                <div class="md:w-1/2">
                    <div class="text-sm text-gray-500">{{ notification.Part.Manufacturer.Name }}</div>
                    <h4 class="mt-0">{{ notification.Part.PartNumber }}</h4>
                    <div>
                        <Button size="sm" @click="goToManageAlerts">
                            {{ $t("Notifications.ManageAlertsLink") }}
                        </Button>
                    </div>
                </div>
                <div class="self-center">
                    <Spinner v-if="isSaving" />
                </div>
            </div>

            <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>

            <p>{{ $t("Notifications.PartNotificationMessage") }}</p>

            <template v-if="notification">
                <NotificationStockAlerts
                    :alerts="notification.StockAlerts"
                    @add="onAddAlert($event, 'stock')"
                    @update="onUpdateAlert($event, 'stock')"
                    @delete="onDeleteAlert($event, 'stock')" />
                <NotificationPriceAlerts
                    :alerts="notification.PriceAlerts"
                    @add="onAddAlert($event, 'price')"
                    @update="onUpdateAlert($event, 'price')"
                    @delete="onDeleteAlert($event, 'price')" />
            </template>
        </DialogContent>
        <NotificationPreview v-else />
    </Dialog>
</template>

<script setup lang="ts">
type AlertType = "price" | "stock";

const api = useApi();
const localePath = useLangPath();
const { gtag } = useGtag();
const isAuthenticated = useStateIsAuthenticated();
const { t } = useI18n();

const emit = defineEmits(["open", "close", "update"]);
defineExpose({ openDialog, openDialogPartRecord });

const isOpen = ref(false);
const isSaving = ref(false);
const errorMessage = ref("");
const notification = ref<PartNotification | null>(null);

function openDialogPartRecord(part: PartRecord, partNotification?: PartNotification | null) {
    const { PartNumber, Description, ImageURL, Manufacturer: mfr } = part;
    const commonPart: CommonPart = {
        PartId: part.Id,
        PartNumber,
        Description,
        ImageUrl: ImageURL,
        IsSelfHostedImage: false,
        PartUrl: part.PartDetailURL,
        Manufacturer: { ...mfr },
    };
    return openDialog(commonPart, partNotification);
}

function openDialog(part: CommonPart, partNotification?: PartNotification | null) {
    gtag("event", "alerts_open_modal", { part_number: part.PartNumber });
    emit("open");

    notification.value = partNotification
        ? { ...partNotification, Part: { ...part } }
        : { Id: 0, StockAlerts: [], PriceAlerts: [], PartId: part.PartId, Part: { ...part } };
    errorMessage.value = "";
    isOpen.value = true;
}

function onAddAlert(alert: StockAlert | PriceAlert, type: AlertType) {
    if (!notification.value) return;
    const copy = { ...notification.value };
    const alerts = type == "price" ? copy.PriceAlerts : copy.StockAlerts;
    alerts.push(alert);
    return saveNotification(copy);
}

function onUpdateAlert(alert: StockAlert, type: AlertType) {
    if (!notification.value) return;
    const copy = { ...notification.value };
    const alerts = type == "price" ? copy.PriceAlerts : copy.StockAlerts;
    const index = alerts.findIndex((item) => item.Id == alert.Id);

    if (index > -1) {
        gtag("event", "alerts_save_" + type, { part_number: copy.Part.PartNumber });
        alerts[index] = alert;
        return saveNotification(copy);
    }
}

function onDeleteAlert(id: number, type: AlertType) {
    if (!notification.value) return;
    const copy = { ...notification.value };
    const alerts = type == "price" ? copy.PriceAlerts : copy.StockAlerts;
    const index = alerts.findIndex((item) => item.Id == id);

    if (index > -1) {
        gtag("event", "alerts_delete", { part_number: copy.Part.PartNumber });
        alerts[index].Status = AlertStatus.Deleted;
        return saveNotification(copy);
    }
}

async function saveNotification(data: PartNotification) {
    isSaving.value = true;
    errorMessage.value = "";
    const previous = notification.value;

    try {
        const { Part: dataPart, ...other } = data;
        // only send required part properties
        const body = { ...other, Part: { PartNumber: dataPart.PartNumber, Manufacturer: dataPart.Manufacturer } };

        notification.value = data; // immediate UI update
        const method = data.Id ? "PUT" : "POST";
        const { StockAlerts, PriceAlerts, Id } = await api<PartNotification>("/api/part-notifications", {
            method,
            body,
        });
        notification.value = { ...data, StockAlerts, PriceAlerts, Id }; // only update specific properties, leave Part alone
        emit("update", notification.value);
    } catch (_err) {
        notification.value = previous; // revert to previous value
        errorMessage.value = t("Notifications.NotificationError");
    } finally {
        isSaving.value = false;
    }
}

function goToManageAlerts() {
    gtag("event", "alerts_manage", { part_number: notification.value?.Part.PartNumber });
    navigateTo(localePath("/my-account/notifications"));
    isOpen.value = false;
}
</script>
